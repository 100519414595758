@import "./variables";
@import "./functions";
@import "./placeholders";
@import "./mixins";
@import "./drepreciated.classes";

@media print {
  @page {
    size: 8.5in 11in;

    :left {
      margin-left: 1cm;
    }

    :right {
      margin-right: 1cm;
    }
  }

  .mat-mdc-tab-body-wrapper,
  .mat-tab-body-content,
  .mat-tab-body,
  .mat-mdc-tab-group {
    overflow: visible !important;
    display: block !important;
  }

  .print--hide {
    display: none !important;
  }

  .page-break {
    break-before: always;
    break-after: avoid;
    break-inside: avoid;

    page-break-before: always;
    page-break-after: avoid;
    page-break-inside: avoid;
  }

  body {
    overflow: visible;
  }

  .background-colour--grey-primary {
    break-inside: avoid;
    break-before: avoid;
    break-after: auto;

    page-break-inside: avoid;
    page-break-before: avoid;
    page-break-after: auto;

    orphans: 4;
    widows: 4;
  }
}
