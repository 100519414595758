.font-weight-bold
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'font-weight-bold' is depreciated; use 'font-weight--bold'";
	}
}

.font-weight-500
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'font-weight-500' is depreciated; use 'font-weight--medium'";
	}
}

.break-spaces
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'break-spaces' is depreciated; please delete";
	}
}

.left-border-section
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'left-border-section' is depreciated; use 'grey-side-bar'";
	}
}

.pull-right
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'pull-right' is depreciated; use 'float--right'";
	}
}

.pull-left
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'pull-left' is depreciated; use 'float--left'";
	}
}

.profile-warning
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'profile-warning' is depreciated; use 'colour--red-primary'";
	}
}

.red-icon
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'red-icon' is depreciated; use 'colour--red-primary'";
	}
}

.green-icon
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'green-icon' is depreciated; use 'colour--green-primary'";
	}
}

.yellow-icon
{
	&::before
	{
		content: "Class 'yellow-icon' is depreciated; use 'colour--yellow-primary'";
	}
}

.bg-gray
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'bg-gray' is depreciated; use 'background-colour--grey-primary'";
	}
}

.bg-dark-gray
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'bg-dark-gray' is depreciated; use 'background-colour--grey-secondary'";
	}
}

.text-center
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'text-center' is depreciated; use 'text-align--center'";
	}
}

.text-left
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'text-left' is depreciated; use 'text-align--left'";
	}
}

.text-right
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'text-right' is depreciated; use 'text-align--right'";
	}
}

.section-divider
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'section-divider' is depreciated; please remove it";
	}
}

.screenLayout
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'screenLayout' is depreciated; use  'display--block'";
	}
}

.bold-font
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'bold-font' is depreciated; use  'font-weight--normal' for 400 weight, 'font-weight--medium' for 500 weight, or 'font-weight--bold' for 700 weight";
	}
}

.tooltip-icon
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'tooltip-icon' is depreciated; no class replacement needed.  Use <button> for on page actions, and <a> for navigation.";
	}
}

.flag-level-1
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'flag-level-1' is depreciated; use 'button--orange-tertiary'"; 
	}
}

.flag-level-2
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'flag-level-2' is depreciated; use 'button--orange-tertiary'"; 
	}
}

.flag-level-3
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'flag-level-3' is depreciated; use 'button--red-tertiary'"; 
	}
}

.flag-level-4
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'flag-level-4' is depreciated; use 'button--orange-tertiary'"; 
	}
}

.flag-level-5
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'flag-level-5' is depreciated; use 'button--red-tertiary'"; 
	}
}

.flag-level-6
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'flag-level-6' is depreciated; use 'button--blue-tertiary'"; 
	}
}

.flag-level-7
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'flag-level-7' is depreciated; use 'button--orange-tertiary'"; 
	}
}

.yellow-tooltip-icon
{
	&::before
	{
		@extend %css-error-message;

		content: "Class 'flag-level-7' is depreciated; use 'colour--yellow-primary'"; 
	}
}

mat-divider
{
	&::before
	{
		@extend %css-error-message;

		content: "Element <mat-divider> is depreciated; please use native element <hr class=\"hr\" /> instead";
	}
}

mat-expansion-panel-header.flex, mat-expansion-panel-header.mat-expansion-panel-header.flex, .mat-expansion-panel-header.flex
{
	&::before
	{
		@extend %css-error-message;
		
		content: "Class 'flex' and associated dependent classes are not needed; 'flex flex--cols-2' CSS code is automatically added.  Ensure classes 'flex-child flex-child--flex-grow' are used in the child element(s).";
	}
}