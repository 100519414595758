$colours: //Map of colours
(
	black: //Variations of the colour black
	(
		primary: #1a1a1a,
		transparent: rgba(0, 0, 0, 0.42),
		border: rgba(0, 0, 0, 0.12),
	),
	white: //Variations of the colour white
	(
		primary: #ffffff,
	),
	gray: //Variations of the colour gray
	(
		primary: #f4f4f4,
		secondary: #cccccc,
		tertiary: #666666,
	),
	green: //Variations of the color green
	(
		primary: #118847,
		index: #008000,
		highlight: #0f6736, //Only to be used for gradiant index
    ),
	red: //Variations of the colour red
	(
		primary: #cd0000,
		index: #ff0000 //Only to be used for gradiant index,
	),
	orange: //Variations of the colour orange
	(
		primary: #c83f0f,
		index: #f06d06 //Only to be used for gradiant index,
	),
	yellow: //Variations of the colour yellow, decreasig in purity
	(
		primary: #906700,
		index: #ffff00 //Only to be used for gradiant index,
	),
	blue: //Variations of the colour blue, decreasing in purity
	(
		primary: #00799f,
		highlight-primary: #b4d5fe,
		highlight-secondary: #3399ff
	),
	transparent: //Variations of transparent
	(
		primary: transparent,
	)
);

//Default values
$size: 1rem; //Equal to the root's font-size (usually 16px determined by the browser settings)

$font-weights:
(
	"normal": 400,
	"medium": 500,
	"bold": 700,
);

//Breakpoints used for AgriSuite
$breakpoints:
(
	desktop:
	(
		min: 1467px,
		max: 1920px
	),
	tablet:
	(
		min: 721px,
		max: 1467px
	),
	mobile:
	(
		min: 360px,
		max: 720px
	),
);

$spaceamounts: (-2, -1, 0, 1, 2, 3, 4, 5);

/* TO DO: Consider revising */
$mat-black-custom: (
  50: #e7e7e7,
  100: #c2c2c2,
  200: #999999,
  300: #707070,
  400: #525252,
  500: #333333,
  600: #2e2e2e,
  700: #272727,
  800: #202020,
  900: #141414,
  A100: #ef6e6e,
  A200: #ea4040,
  A400: #f60000,
  A700: #dd0000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

/* TO DO: Consider revising */
$mat-green-custom: (
  50: #e5f4ed,
  100: #bfe5d2,
  200: #95d3b4,
  300: #6ac196,
  400: #4ab480,
  500: #2aa769,
  600: #259f61,
  700: #1f9656,
  800: #198c4c,
  900: #0f7c3b,
  A100: #aeffcb,
  A200: #7bffab,
  A400: #48ff8a,
  A700: #2fff7a,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);