@use "base";
@use "sass:map";

[data-homeComponent] {
  max-width: base.map-deep-get(base.$breakpoints, "desktop", "max");
  margin-bottom: 0;

  > .flex-child {
    padding: base.multiply-two-numbers(base.$size, 2) !important;
    align-self: stretch;

    h1,
    h2 {
      margin-top: 0;
      margin-bottom: base.$size;
      font-weight: map.get(base.$font-weights, "normal");
    }

    &:first-child {
      color: base.map-deep-get(base.$colours, white, "primary");
      background: linear-gradient(
          base.map-deep-get(base.$colours, black, "primary"),
          transparent
        ),
        url("../../../assets/images/crops-sm.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: left;
      background-position-y: bottom;

      h1 {
        color: inherit;
        text-align: center;
      }

      @media (max-width: base.map-deep-get(base.$breakpoints, tablet, "max")) {
        width: 100%;
      }
    }

    &:nth-child(2) {
      ul {
        padding-left: 0;

        li {
          list-style-type: none;
          margin-bottom: 0;

          > .flex-child {
            margin-bottom: base.$size;
            align-self: stretch;

            button {
              margin: 0;
              padding: 0;
            }

            app-card-tooltip {
              width: 100%;
              display: block;
              width: base.multiply-two-numbers(base.$size, 35);
              position: relative;
            }

            &:last-child {
              box-shadow: 0 0 (base.multiply-two-numbers(base.$size, 0.5))
                base.map-deep-get(base.$colours, gray, "tertiary");
              border-radius: base.divide-two-numbers(base.$size, 2);
              padding: base.$size !important;
              display: none;
              transition: all 0.5s linear;
            }
          }
        }
      }
    }

    &:last-child {
      background-color: base.map-deep-get(base.$colours, gray, "primary");

      button {
        margin-left: 0;
      }

      li {
        list-style-type: circle;
      }
    }

    @media (max-width: base.map-deep-get(base.$breakpoints, "mobile", "max")) {
      padding: base.$size !important;
    }
  }

  #forgotPassword {
    color: base.map-deep-get(base.$colours, green, "index") !important;
  }
}
