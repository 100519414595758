@use "base";
@use "sass:math";
@use "sass:map";

[data-secureHomeComponent] {
  overflow: visible;

  .action-button {
    cursor: pointer;
  }

  .app-loading {
    width: base.$size;
    margin: 0 auto;
    margin-top: base.$size;
  }

  .label {
    padding: 2px 6px;
    font-size: base.$size;
    background-color: base.map-deep-get(base.$colours, grey, "secondary");
    color: base.map-deep-get(base.$colours, black, "primary");
    font-weight: map.get(base.$font-weights, "medium");
    margin-right: 0.5em;
  }

  .label-filter-box {
    color: base.map-deep-get(base.$colours, black, "primary");
    font-size: base.$size;
    opacity: 1;

    &:disabled {
      color: base.map-deep-get(base.$colours, gray, "tertiary");
    }
  }

  .filter-applied {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-appearance-outline .mat-form-field-outline {
      color: base.map-deep-get(
        base.$colours,
        transparent,
        "primary"
      ) !important;
      border: (base.multiply-two-numbers(base.$size, 0.15)) solid
        base.map-deep-get(base.$colours, black, "primary");
      border-radius: base.multiply-two-numbers(base.$size, 0.5);
    }
  }

  *:not(mat-toolbar *):not(mat-toolbar-row *) {
    font-size: base.$size;
    cursor: inherit;
  }

  table {
    width: 100%;

    tr {
      th:last-child {
        visibility: hidden;
      }
    }
  }

  tr.mat-mdc-row {
    &:hover,
    &:active,
    &:focus {
      // border: base.multiply-two-numbers(base.$size, 0.1) solid base.map-deep-get(base.$colours, blue, "highlight-secondary");
      // @extend %transition;
      td.mat-mdc-cell {
        cursor: pointer;
        background-color: base.map-deep-get(base.$colours, gray, "primary");

        // @extend %transition;
        // &.mat-column-name
        // {
        // 	color: base.map-deep-get(base.$colours, green, "highlight");
        // 	@extend %transition;
        // }
        &:last-child {
          cursor: default;
        }
      }
    }
  }

  th.mat-mdc-header-cell {
    color: base.map-deep-get(base.$colours, black, "primary");
    font-size: base.$size;
    font-weight: map.get(base.$font-weights, "medium");
  }

  td.mat-column-actions {
    color: base.map-deep-get(base.$colours, green, "primary");
  }

  th,
  td {
    padding-left: base.$size;
    padding-right: base.$size;
    padding-top: base.divide-two-numbers(base.$size, 2);
    padding-bottom: base.divide-two-numbers(base.$size, 2);
    text-align: left;
    cursor: pointer;

    &:first-child {
      width: min-content;
    }

    &:nth-child(2) {
      width: 40%;
    }

    &:nth-child(3) {
      width: 30%;
    }

    &:nth-child(4) {
      width: 20%;
    }

    &:last-child {
      width: 10%;
      cursor: default;
    }

    @media (max-width: 476px) {
      &:first-child {
        width: 90%;
      }

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        display: none;
      }
    }
  }

  .half-spacer {
    flex: 0.5 1 auto;
  }

  .prime-content {
    background-color: base.map-deep-get(base.$colours, white, "primary");

    .mat-mdc-form-field {
      margin: base.$size base.$size 0 base.$size;
    }
  }

  .mat-mdc-text-field-wrapper {
    background-color: base.map-deep-get(base.$colours, white, "primary");
  }

  #searchRow {
    padding: base.$size;
    margin: 0;
    gap: base.$size;
    align-items: center;

    &Hover {
      position: sticky;
      height: max-content;
      top: 0;
      z-index: 1;
      box-shadow: 0 0 base.divide-two-numbers(base.$size, 2) 0
        base.map-deep-get(base.$colours, black, "primary");
      background-color: base.map-deep-get(base.$colours, gray, "primary");
      padding: base.$size;
      gap: base.$size;
      align-items: baseline;

      #searchNfilter {
        align-items: center;
        margin-bottom: 0 !important;
      }

      mat-form-field {
        margin-bottom: 0 !important;
      }
    }

    .flex-child:not(button) {
      margin: 0 !important;
      padding: 0 !important;
    }

    mat-form-field {
      margin-bottom: 0;
    }

    button {
      margin: 0;
      padding: 1rem;
    }

    #searchNfilter {
      margin: 0 !important;
      padding: 0 !important;
      align-items: center;
      gap: base.$size;

      mat-form-field {
        margin-bottom: 0 !important;
      }

      > .flex-child {
        @media (max-width: 984px) {
          width: calc(
            50% - base.multiply-two-numbers(base.$size, 0.5)
          ) !important;

          &:last-child {
            margin-bottom: base.multiply-two-numbers(
              base.$size,
              1.5
            ) !important;
            width: fit-content !important;
            flex-grow: 0;
            flex-shrink: 1;
          }
        }

        @media (max-width: 662px) {
          width: 100% !important;

          &:last-child {
            margin-bottom: base.multiply-two-numbers(
              base.$size,
              1.5
            ) !important;
            width: 100% !important;
            flex-grow: 1;
            flex-shrink: 0;
          }
        }

        &:last-child:is(.flex-child--placeholder) {
          margin-bottom: 0 !important;
        }
      }
    }

    #searchInput {
      height: 100%;

      .mat-mdc-notch-piece {
        display: flex;
        align-items: baseline;
      }

      .mat-mdc-form-field-flex {
        height: 100%;
        align-items: center;
      }

      .mdc-floating-label {
        display: flex;
        align-items: center;
        height: 100%;
        top: 50%;
        width: fit-content;
        overflow: visible;

        &.mdc-floating-label--float-above {
          top: base.multiply-two-numbers(base.$size, 2);
          height: auto;
        }
      }
    }

    app-label-filter {
      > div {
        > mat-form-field {
          height: 100%;

          .mat-mdc-form-field-flex,
          .mat-mdc-form-field-infix {
            height: 100%;
            display: flex;
            align-content: center;
            padding: 0;
          }
        }
      }

      .mdc-notched-outline {
        height: 96%;
      }
    }

    fieldset {
      margin: 0;

      .flex {
        margin: 0;
        gap: base.$size;
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  [buttonContainerSecure] {
    gap: base.$size;
    margin-bottom: 0 !important;

    button {
      padding: 0 base.$size !important;
      height: base.multiply-two-numbers(base.$size, 4);
    }
  }

  [data-authHomeComponentNoPlanMessage] {
    padding: base.$size;
  }

  button.button.button--tertiary {
    margin-top: base.divide-two-numbers(base.$size, 2);
  }

  .mat-elevation-z3 {
    .mat-toolbar-row {
      padding: base.$size;
    }
  }

  .mat-mdc-menu-item,
  .colour--red-primary {
    font-weight: map.get(base.$font-weights, "medium");
  }

  .drawer-container.mat-drawer-container {
    padding-bottom: base.multiply-two-numbers(base.$size, 1.5);
    z-index: 0;

    .mat-drawer {
      padding: 0 1em;
      width: 300px;
      background-color: base.map-deep-get(base.$colours, white, "primary");
    }

    .section-title {
      margin-top: base.$size;
    }
  }

  .mat-mdc-menu-panel {
    max-width: 380px !important;
  }

  app-label-filter mat-form-field {
    height: 59px;
  }

  .mat-mdc-icon-button {
    --mat-mdc-button-persistent-ripple-color: none;
  }

  .mat-mdc-button-base {
    cursor: pointer;
  }

  mat-paginator {
    border-top: 0.1rem solid base.map-deep-get(base.$colours, black, "primary");
    box-shadow: 0 base.multiply-two-numbers(base.$size, 0.4)
      base.multiply-two-numbers(base.$size, 0.4)
      base.multiply-two-numbers(base.$size, 0.1)
      base.map-deep-get(base.$colours, gray, "secondary");
  }
}
