@use "sass:map";

%flex {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: flex-start;
  width: 100%;
  margin-bottom: $size;
}

%sticky {
  position: sticky !important;
  top: 0;
  display: block;
}

%gradiant-index {
  height: 1.2em;
  background-image: linear-gradient(
    to right,
    map-deep-get($colours, green, "index"),
    map-deep-get($colours, yellow, "index"),
    map-deep-get($colours, orange, "index"),
    map-deep-get($colours, red, "index")
  );
  width: 100%;
  max-width: 98.5%;
  margin: 0 auto;
}

%header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: map.get($font-weights, "medium");
  width: 100%;
  line-height: 1;
  color: map-deep-get($colours, black, "primary");
  align-self: center;
}

%header--border {
  font-size: multiply-two-numbers($size, 1.125);
  margin-top: 0;
  margin-bottom: $size;
  border-bottom: multiply-two-numbers($size, 0.1) solid
    map-deep-get($colours, gray, "secondary");
  padding-bottom: multiply-two-numbers($size, 0.1);
}

%css-error-message {
  font-family: inherit;
  font-size: $size;
  color: map-deep-get($colours, red, "primary");
  border: multiply-two-numbers($size, 0.1) solid
    map-deep-get($colours, red, "primary");
  background-color: map-deep-get($colours, white, "primary");
  display: block;
  max-width: map-deep-get($breakpoints, "mobile", "min");
  width: 100vw;
  z-index: 1000;
  position: relative;
  padding: $size;
  white-space: normal;
  box-shadow: 0 0 $size map-deep-get($colours, red, "primary");
  border-radius: $size;
  visibility: visible;
}

%disabled {
  opacity: 0.3;
  cursor: not-allowed;
  * {
    cursor: not-allowed;
  }
}

%transition {
  transition: all 0.25s linear 0s;
}
