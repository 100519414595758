@use "base";
@use "sass:map";

[data-importWorksheetDialogComponent] {
  max-width: base.multiply-two-numbers(base.$size, 56.25);

  .mat-step-icon {
    background-color: base.map-deep-get(
      base.$colours,
      gray,
      "secondary"
    ) !important;
  }

  .mat-step-icon-selected {
    background-color: base.map-deep-get(
      base.$colours,
      green,
      "primary"
    ) !important;
  }

  #importInstructions {
    list-style: none;
    margin-left: 10;
    padding-left: base.$size;
  }

  #importInstructions > li:before {
    display: inline-block;
    content: "-";
    width: base.$size;
    margin-left: base.negate-number(base.$size);
  }

  .input_fileupload--hidden {
    display: none;
  }

  [data-worksheetList],
  [data-importedList] {
    overflow: auto;
    height: 140px;
    border-radius: base.multiply-two-numbers(base.$size, 0.3);
    border: base.multiply-two-numbers(base.$size, 0.1) solid
      base.map-deep-get(base.$colours, gray, "primary");
    padding: base.multiply-two-numbers(base.$size, 0.5);
  }

  .align-baseline {
    align-items: baseline;
  }

  .align-end {
    align-items: end;
  }

  .ws-checkbox {
    width: base.multiply-two-numbers(base.$size, 1.25);
  }

  .ws-name {
    max-width: base.multiply-two-numbers(base.$size, 13.75);
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  mat-checkbox.mat-checkbox-disabled span.mat-checkbox-inner-container {
    background-color: base.map-deep-get(
      base.$colours,
      gray,
      "secondary"
    ) !important;
  }

  #importDetails {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: base.$size;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-infix {
      padding: 0;
      border-top: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-label-wrapper {
      top: base.negate-number(base.multiply-two-numbers(base.$size, 0.84375));
      padding-top: base.multiply-two-numbers(base.$size, 0.84375);
    }
  }

  .mat-step-label-selected {
    font-weight: map.get(base.$font-weights, "medium");
  }
}
