@use "base";

app-label-filter
{
	button
	{
		margin: 0 !important;
	}

	mat-form-field
	{
		margin-bottom: 0 !important;
	}

	.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before
	{
		opacity: 1;
		background-color: base.map-deep-get(base.$colours, transparent, "primary");
	}

	.labelFilterComponentMatMenuClass
	{
		width: base.multiply-two-numbers(base.$size, 22);

		.mat-mdc-dialog-content.mdc-dialog__content[mat-dialog-content]
		{
			width: max-content !important;
		}
	}
}